import type { ComponentType, FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import { ContentIcon as ConfluenceContentIcon } from '@confluence/icons/entry-points/ContentIcon';
import { emojiExp } from '@confluence/emoji-title/entry-points/helpers';
import { isValidEmojiOrFavicon } from '@confluence/emoji-title/entry-points/isValidEmojiOrFavicon';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';

import { PageCardContext } from '../PageCardContext';

type ContentIconProps = {
	wrapper?: ComponentType;
};

const i18n = defineMessages({
	page: {
		id: 'page-card.page.label',
		defaultMessage: 'Page',
		description: 'Label for page icon. Denotes this content is a page.',
	},
	blogpost: {
		id: 'page-card.blog.label',
		defaultMessage: 'Blog',
		description: 'Label for blog icon. Denotes this content is a blog.',
	},
	whiteboard: {
		id: 'page-card.whiteboard.label',
		defaultMessage: 'Whiteboard',
		description: 'Label for whiteboard icon. Denotes this content is a whiteboard.',
	},
	database: {
		id: 'page-card.database.label',
		defaultMessage: 'Database',
		description: 'Label for database icon. Denotes this content is a database.',
	},
	folder: {
		id: 'page-card.folder.label',
		defaultMessage: 'Folder',
		description: 'Label for folder icon. Denotes this content is a folder.',
	},
});

//the & div styles fix the emoji loader grey square
//the & span fixes the negative margin in the ak component chopping off the bottom of the emoji
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const EmojiContainer = styled.div<{ compact?: boolean }>((props) =>
	props.compact
		? `
    height: 16px;
    & div{
     margin: 0;
     line-height: 16px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    & span {
      display: block;
    }
  `
		: `
    height: 26px;
    width: 26px;
    font-size: 26px;
    line-height: 26px;
  `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	overflow: 'hidden',
	maxWidth: '28px',
	width: '28px',
	paddingRight: token('space.075', '6px'),
	paddingTop: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	fontSize: '26px',
	lineHeight: '26px',
	cursor: 'initial',
});

export const ContentIcon: FC<ContentIconProps> = ({ wrapper }) => {
	const {
		type,
		title: { emoji },
		appearance,
	} = useContext(PageCardContext);
	const intl = useIntl();
	const compact = appearance === 'compact-list';
	const Wrapper = wrapper || EmojiWrapper;
	return isValidEmojiOrFavicon(emoji, type) ? (
		<EmojiContainer compact={compact}>
			<LazyEmojiComponentLoader
				emoji={emoji}
				contentType={type}
				height={compact ? 16 : 26}
				context="pageCard"
				wrapper={Wrapper}
			/>
		</EmojiContainer>
	) : emojiExp.test(emoji) ? (
		<EmojiContainer compact={compact}>{emoji}</EmojiContainer>
	) : (
		<ConfluenceContentIcon
			type={type}
			label={intl.formatMessage(i18n[type])}
			size={compact ? 'small' : 'medium'}
		/>
	);
};
